<template>
  <div id="data-list-list-view" class="data-list-container">
    <h1>{{ $t("activated_staffs") }}</h1>
    <br>
    <!-- <vx-card ref="filterCard" title="Filtre" class="user-list-filters mb-4"  >
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <p class="mb-2" > Fonctions </p>
          <v-select v-model="formation_filter" :options="formations_list" :reduce="rep => rep.id" label="name" autocomplete >
          </v-select>
        </div>
        <div class="vx-col w-1/2">
          <statistics-card-line
              hideChart
              class="mb-base"
              icon="UserPlusIcon"
              icon-right
              :statistic="new Intl.NumberFormat('de-DE').format( ( nbr_staffs || 0) )"
              :statisticTitle="$t('staffs')"/>
        </div>

      </div>
    </vx-card> -->
    <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="staffs">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ADD NEW -->
          <!-- <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">{{ $t("Ajouter") }}</span>
          </div> -->
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ staffs.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : staffs.length }} of {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=60">
              <span>60</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=80">
              <span>80</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="staffs">{{ $t("staffs") }}</vs-th>
<!--           <vs-th sort-key="birthdate">Date de naissance</vs-th>
-->          <vs-th sort-key="nationality">Nationalité</vs-th>
        <vs-th sort-key="company">Compagnie</vs-th>
<!--           <vs-th sort-key="contractType">Type de contrat</vs-th>
-->          <vs-th sort-key="function">Fonction</vs-th>
          <vs-th sort-key="greyCard">{{ $t("nokiaValidation") }}</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.fullName}}</p>
          </vs-td>
        <!--   <vs-td>
            <p class="product-name font-medium truncate">{{tr.birthdate}}</p>
          </vs-td> -->
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.nationality.name}}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.company.name}}</p>
          </vs-td>
         <!--  <vs-td>
            <p class="product-name font-medium truncate">{{contratObject[tr.contractType]}}</p>
          </vs-td> -->
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.function.name}}</p>
          </vs-td>
          <vs-td>
          <vs-chip :color="getOrderStatusColor(tr.activated)" class="product-order-status">{{validationObject[tr.activated]}}</vs-chip>
          </vs-td>
          <vs-td class="whitespace-no-wrap">
            <div class="flex">
              <vx-tooltip :text="$t('Voir')" color="success">
                <feather-icon
                  style="color: #00a00d"
                  icon="EyeIcon"
                  svgClasses="w-5 h-5 hover:text-success stroke-current"
                  class="mr-2"
                  @click="Show_data(tr)"
                />
              </vx-tooltip>
              <vx-tooltip :text="$t('Modifier')" color="warning">
                <feather-icon
                  style="color: #FF9F43"
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-warning stroke-current"
                  class="mr-2"
                  @click="Edit(tr)"
                />
              </vx-tooltip>
              <vx-tooltip :text="$t('Supprimer')" color="danger">
                <feather-icon
                  @click="deleteData(tr.id)"
                  style="color: red"
                  icon="TrashIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="mr-2"
                />
              </vx-tooltip>
              <vx-tooltip v-if="role !=='managerSubcontractor'" text="Valier le personnel" color="success">
              <feather-icon @click="comfirmation(tr.id)" style="color: #06ac0e" icon="CheckCircleIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" class="mr-2"/>
            </vx-tooltip>
            <vx-tooltip v-if="role !=='managerSubcontractor'" text="Rejeter le personnel" color="danger">
              <feather-icon @click="rejet_Pop(tr.id)" style="color: #ff6141" icon="XCircleIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="mr-2"/>
            </vx-tooltip>
            <vx-tooltip v-if="tr.activated === 0 && role !=='managerSubcontractor' " text="Rapport" color="dark">
              <feather-icon @click="rapport(tr.satffComments)" style="color: black" icon="FileTextIcon" svgClasses="w-5 h-5 hover:text-dark stroke-current" class="mr-2"/>
            </vx-tooltip>
            </div>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-popup
    v-if="activePrompt1" 
    fullscreen 
    class="calendar-event-dialog"
    title="Ajouter un Personnel"
    :active.sync="activePrompt1">
    <div class="clearfix">
    <div class="vx-row">
      <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
        <!-- NOM ET PRENOMS-->
        <p>Nom & Prénoms<b style="color: #ff6141">*</b></p>
        <vs-input
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="fullName"
          v-model="fullName"
          class="w-full"
        />
        <span class="text-danger text-sm" v-show="errors.has('fullName')">{{ errors.first("fullName") }}</span>

         <p> Type de contrat <b style="color: #ff6141" >*</b> </p>
            <vs-select
              v-model="contractType"
              data-vv-validate-on="blur"
              v-validate="'required'"
              autocomplete
              name="contractType"
              class="w-full"
          >
        <vs-select-item :key="item" :value="item.key" :text="item.name" v-for="item in contract_choises" /></vs-select>
        
        <span class="text-danger text-sm" v-show="errors.has('contractType')">{{ errors.first("contractType") }}</span>
         
          <p>Type de carte<b style="color: #ff6141">*</b></p>
          <vs-select
              v-model="cardType"
              data-vv-validate-on="blur"
              v-validate="'required'"
              autocomplete
              name="cardType"
              class="w-full"
          >
        <vs-select-item :key="item1" :value="item1.key" :text="item1.name" v-for="item1 in card_choises" /></vs-select>
        <span class="text-danger text-sm" v-show="errors.has('cardType')">{{ errors.first("cardType") }}</span>

          <p>N° de Carte ou Passport<b style="color: #ff6141">*</b></p>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="idCardOrPassportNumber"
            v-model="idCardOrPassportNumber"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('idCardOrPassportNumber')">{{ errors.first("idCardOrPassportNumber") }}</span>
          <p>Personnel externe appartenant à une autre entreprise<b style="color: #ff6141">*</b></p>
          <vs-checkbox 
              v-model="externalStaffOwnedByAnotherCompany"
              data-vv-validate-on="blur"
              v-validate="'required'"
              name="externalStaffOwnedByAnotherCompany"
              class="w-full">
          </vs-checkbox>
          <span class="text-danger text-sm" v-show="errors.has('externalStaffOwnedByAnotherCompany')">{{ errors.first("externalStaffOwnedByAnotherCompany") }}</span>
  
          <p>Date de naissance <b style="color: #ff6141">*</b></p>
          <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="birthdate"
            v-validate="'required'"
            name="birthdate"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('birthdate')">{{ errors.first("birthdate") }}</span>
      </div>

        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
    
  
          <p> Nationalité <b style="color: #ff6141" >*</b> </p>
            <v-select
              v-validate="'required'"
              name="nationality"
              :reduce="rep => rep.id"
              class="w-full" label="name"
              v-model="nationality"
              :options="nationalities">
            </v-select>
          <span class="text-danger text-sm" v-show="errors.has('nationality')">{{ errors.first("nationality") }}</span>
          
          <p v-if="role !== 'managerSubcontractor'">Compagnie<b style="color: #ff6141">*</b></p>
          <v-select
          v-if="role !== 'managerSubcontractor'"
              v-validate="'required'"
              name="company"
              :reduce="rep => rep.id"
              class="w-full" label="name"
              v-model="company"
              :options="companies">
            </v-select>
           <span v-if="role !== 'managerSubcontractor'" class="text-danger text-sm" v-show="errors.has('company')">{{ errors.first("company") }}</span>
          
           <p>Ancienneté dans l'entreprise <b style="color: #ff6141">*</b></p>
          <vs-input-number
          :min="0"
          :max="100"  
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="seniorityInTheCompany"
          v-model="seniorityInTheCompany"
          class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('seniorityInTheCompany')">{{ errors.first("seniorityInTheCompany") }}</span>
          
          <p>Fonction<b style="color: #ff6141">*</b></p>
          <v-select
              v-validate="'required'"
              name="job_function"
              :reduce="rep => rep.id"
              class="w-full" label="name"
              v-model="job_function"
              :options="functions">
            </v-select>
           <span class="text-danger text-sm" v-show="errors.has('job_function')">{{ errors.first("job_function") }}</span>
           <p>Ancienneté dans la fonction<b style="color: #ff6141">*</b></p>
          <vs-input-number
          :min="0"
          :max="100"  
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="seniorityInTheFunction"
          v-model="seniorityInTheFunction"
          class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('seniorityInTheFunction')">{{ errors.first("seniorityInTheFunction") }}</span>
          
          <p v-if="externalStaffOwnedByAnotherCompany ==1" >Nom de l'entreprise externe<b style="color: #ff6141">*</b></p>
          <vs-input
          v-if="externalStaffOwnedByAnotherCompany ==1" 
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="externalCompanyName"
            v-model="externalCompanyName"
            class="w-full"
          />
          <span v-if="externalStaffOwnedByAnotherCompany ==1" class="text-danger text-sm" v-show="errors.has('externalCompanyName')">{{ errors.first("externalCompanyName") }}</span>
          
        </div>

      <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
          
          <p v-if="externalStaffOwnedByAnotherCompany ==1" >Référence du contrat d'entreprise externe<b style="color: #ff6141">*</b></p>
          <vs-input
          v-if="externalStaffOwnedByAnotherCompany ==1" 
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="externalCompanyContractReference"
            v-model="externalCompanyContractReference"
            class="w-full"
          />
          <span v-if="externalStaffOwnedByAnotherCompany ==1" class="text-danger text-sm" v-show="errors.has('externalCompanyContractReference')">{{ errors.first("externalCompanyContractReference") }}</span>
          

          <p v-if="formations_tabCheck === true">Identifiant du permis de conduire<b style="color: #ff6141">*</b></p>
          <vs-input
          v-if="formations_tabCheck === true"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="drivingLicenseID"
            v-model="drivingLicenseID"
            class="w-full"
          />
          <span v-if="formations_tabCheck === true" class="text-danger text-sm" v-show="errors.has('drivingLicenseID')">{{ errors.first("drivingLicenseID") }}</span>
          <p v-if="formations_tabCheck=== true" >Ancienneté en conduite <b style="color: #ff6141">*</b></p>
          <vs-input-number
          v-if="formations_tabCheck === true"
          :min="0"
          :max="100"  
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="seniorityInDriving"
          v-model="seniorityInDriving"
          class="w-full"
          />
          <span v-if="formations_tabCheck === true" class="text-danger text-sm" v-show="errors.has('seniorityInDriving')">{{ errors.first("seniorityInDriving") }}</span>
          
          <p  >contrôle médical<b style="color: #ff6141">*</b></p>

          <vs-checkbox 
              v-model="medicalCheck"
              data-vv-validate-on="blur"
              v-validate="'required'"
              autocomplete
              name="medicalCheck"
              class="w-full">
          </vs-checkbox>
        
          <span  class="text-danger text-sm" v-show="errors.has('medicalCheck')">{{ errors.first("medicalCheck") }}</span>
          
          <p v-if="medicalCheck ==1">Date du contrôle médical <b style="color: #ff6141">*</b></p>
          <flat-pickr 
          v-if="medicalCheck ==1"
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="medicalCheckDate"
            v-validate="'required'"
            name="medicalCheckDate"
            class="w-full" />
          <span v-if="medicalCheck ==1" class="text-danger text-sm" v-show="errors.has('medicalCheckDate')">{{ errors.first("medicalCheckDate") }}</span>
  
            <!-- image -->
       <p  v-if="medicalCheck ==1" class="pt-4">Preuve médicale<b style="color: #ff6141" ></b> </p>
       <div  v-if="medicalCheck ==1" class="upload-img mt-2">
        <input  type="file" class="hidden" ref="uploadImgInput6" @change="updateCurrImg6"  accept="image/*">
        <span><b style="color:orange">{{ name_file6 }}</b></span>
        <vs-button  @click="$refs.uploadImgInput6.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
      </div>
          
      </div>

    <vx-card class="mt-10" no-shadow card-border>
     <div class="vx-row ">
      <div class="vx-col w-full">
        <div class="flex items-end px-3">
          <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
          <span class="font-medium text-lg leading-none">Informations sur la formation </span>
        </div>
        <vs-divider />
      </div>
    </div>
    <div class="vx-row ">
  </div>
    <div class="vx-row ">
      <div class="vx-col w-full">
        <div class="vx-row">
      <div class="vx-col md:w-1/3 sm:w-1/3 w-full">
          <label class="text-sm opacity-75 mp-2">Formation<b style="color: #ff6141" >*</b></label>
          <vs-select
              :closeOnSelect="true"
              data-vv-validate-on="blur"
              autocomplete
              v-model="formations_tab"
              class="w-full "
              name="formations"
          >
          <vs-select-item :key="item4" :value="item4" :text="item4.name" v-for="item4 in formations_list" />
          </vs-select>
      </div>
    <div v-if="formations_tab" class="vx-col md:w-1/3 sm:w-1/3 w-full">
      <p >Preuve<b style="color: #ff6141" ></b> </p>
      <div class="upload-img mt-2">
        <input  type="file" class="hidden" ref="uploadImgInput7" @change="updateCurrImg7"  accept="image/*">
        <span><b style="color:orange">{{ name_file7 }}</b></span>
        <vs-button  @click="$refs.uploadImgInput7.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
      </div>
        
    </div>
  </div>
    </div>
    <div v-if="formations_tab" class="vx-col md:w-1/6 sm:w-1/2 w-full">
      <label class="text-sm opacity-75">Date de la formation<b style="color: #ff6141" >*</b></label>
      <flat-pickr 
      :config="configdateTimePicker1" 
      @on-change="onFromChange" 
      label-placeholder=" Date"
      v-model="dateOfTrainings_tab"
      name="dateOfTrainings"
      class="w-full" />
    </div>

    <div v-if="formations_tab"  class="vx-col  md:w-1/6 sm:w-1/2 w-full">
      <label class="text-sm opacity-75">Date d'obtention<b style="color: #ff6141" >*</b></label>
      <flat-pickr 
      :config="configdateTimePicker2" 
      @on-change="onToChange"
      label-placeholder=" Date"
      name="dateOfObtainings"
      v-model="dateOfObtainings_tab"
      class="w-full" />
     
    </div>

    <div v-if="formations_tab"  class="vx-col  md:w-1/6 sm:w-1/2 w-full">
      <label class="text-sm opacity-75">Organismes formateurs<b style="color: #ff6141" >*</b></label>
      <vs-input
      data-vv-validate-on="blur"
      name="trainerOrganisms"
      v-model="trainerOrganisms_tab"
      class="w-full" />

    </div>

    <div v-if="formations_tab" class="vx-col  md:w-1/6 sm:w-1/2 w-full">
      <label class="text-sm opacity-75">Références du certificat<b style="color: #ff6141" >*</b></label>
      <vs-input
      data-vv-validate-on="blur"
      name="idCertificateReferences"
      v-model="idCertificateReferences_tab"
      class="w-full" />

    </div>

    <div v-if="formations_tab" class="vx-col md:w-1/6 sm:w-1/2 w-full ">
      <label class="text-sm opacity-75">Dates de validité<b style="color: #ff6141" >*</b></label>
      <flat-pickr 
      :config="configdateTimePicker3" 
      @on-change="onFromChange_" 
      label-placeholder=" Date"
      v-model="validityDates_tab"
      name="validityDates"
      class="w-full" />
    </div>

    <div v-if="formations_tab" class="vx-col md:w-1/6 sm:w-1/2 w-full ">
    <label class="text-sm opacity-75">Prochaine mise à niveau<b style="color: #ff6141" >*</b></label>
    <flat-pickr 
      :config="configdateTimePicker4" 
      @on-change="onToChange_"
      label-placeholder=" Date"
      v-model="dateOfTheNextUpgrades_tab"
      name="dateOfTheNextUpgrades"
      class="w-full" />
    </div>

    <div v-if="formations_tab" class="vx-col md:w-full sm:w-full w-full ">
        <div class="flex flex-wrap justify-between mt-5 ">
            <vs-button v-if="!update_produitChek" :disabled="!validate" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_produit">Ajouter</vs-button>
            
            <vs-button v-if="update_produitChek" :disabled="!validate" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="updateProduit">Modifier</vs-button>

            <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData">Annuler</vs-button>
          </div>
    </div>
  </div>
  <div class="vx-row ">
    <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_computed">
    <template slot="header">
      <h3>
        Formation(s)
      </h3>
    </template>
    <template slot="thead">
      <vs-th hidden  >N°</vs-th>
      <vs-th>Formation</vs-th>
      <vs-th>Date de formation</vs-th>
      <vs-th>Date d'obtention</vs-th>
      <vs-th>Organisme formateur</vs-th>
      <vs-th>Référence du certificat d'identification</vs-th>
      <vs-th>Date de validité</vs-th>
      <vs-th>Prochaine mise à niveau</vs-th>
      <vs-th>Preuve</vs-th>

      <vs-th>Actions</vs-th>
    </template>
    <template slot-scope="{data}">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
      
        <vs-td hidden :data="data[indextr].id">
          {{data[indextr].id}}
        </vs-td>
        <vs-td :data="data[indextr].formations">
          {{data[indextr].formations.name}}
        </vs-td>
        <vs-td :data="data[indextr].dateOfTrainings">
          {{data[indextr].dateOfTrainings}}
        </vs-td>
        <vs-td :data="data[indextr].dateOfObtainings">
          {{data[indextr].dateOfObtainings}}
        </vs-td>
        <vs-td :data="data[indextr].trainerOrganisms">
          {{data[indextr].trainerOrganisms}}
        </vs-td>
         <vs-td :data="data[indextr].idCertificateReferences">
          {{data[indextr].idCertificateReferences}}
        </vs-td>
        <vs-td :data="data[indextr].validityDates">
          {{data[indextr].validityDates}}
        </vs-td>
        <vs-td :data="data[indextr].dateOfTheNextUpgrades">
          {{data[indextr].dateOfTheNextUpgrades}}
        </vs-td>
        <vs-td :data="data[indextr].file">
          {{data[indextr].file}}
        </vs-td>
        <vs-td class="whitespace-no-wrap">
          <div class="flex">
            <vs-button @click.stop="update_pro_chek(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
            
            <vs-button @click.stop="deleteProduit(data[indextr].id, data[indextr].file)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
        
          </div>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
  </div>

    </vx-card>

    </div>
    <vs-button color="success"  class="float-right mt-4" @click="formation_validate()" >Envoyer</vs-button>
   </div>
    </vs-popup>
    
    <vs-popup
    v-if="activePrompt2" 
    fullscreen 
    class="calendar-event-dialog"
    title="Modifier un Personnel"
    :active.sync="activePrompt2">
    <div class="clearfix">
      <div class="vx-row">
      <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
        <!-- NOM ET PRENOMS-->
        <p>Nom complet<b style="color: #ff6141">*</b></p>
        <vs-input
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="fullName"
          v-model="fullName"
          class="w-full"
        />
        <span class="text-danger text-sm" v-show="errors.has('fullName')">{{ errors.first("fullName") }}</span>

         <p> Type de contrat <b style="color: #ff6141" >*</b> </p>
            <vs-select
              v-model="contractType"
              data-vv-validate-on="blur"
              v-validate="'required'"
              autocomplete
              name="contractType"
              class="w-full"
          >
        <vs-select-item :key="item" :value="item.key" :text="item.name" v-for="item in contract_choises" /></vs-select>
        
        <span class="text-danger text-sm" v-show="errors.has('contractType')">{{ errors.first("contractType") }}</span>
         
          <p>Type de carte<b style="color: #ff6141">*</b></p>
          <vs-select
              v-model="cardType"
              data-vv-validate-on="blur"
              v-validate="'required'"
              autocomplete
              name="cardType"
              class="w-full"
          >
        <vs-select-item :key="item1" :value="item1.key" :text="item1.name" v-for="item1 in card_choises" /></vs-select>
        <span class="text-danger text-sm" v-show="errors.has('cardType')">{{ errors.first("cardType") }}</span>

          <p>N° de Carte ou Passport<b style="color: #ff6141">*</b></p>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="idCardOrPassportNumber"
            v-model="idCardOrPassportNumber"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('idCardOrPassportNumber')">{{ errors.first("idCardOrPassportNumber") }}</span>
          <p>Personnel externe appartenant à une autre entreprise<b style="color: #ff6141">*</b></p>
          <vs-checkbox 
              v-model="externalStaffOwnedByAnotherCompany"
              data-vv-validate-on="blur"
              v-validate="'required'"
              autocomplete
              name="externalStaffOwnedByAnotherCompany"
              class="w-full">
         </vs-checkbox>
          <span class="text-danger text-sm" v-show="errors.has('externalStaffOwnedByAnotherCompany')">{{ errors.first("externalStaffOwnedByAnotherCompany") }}</span>
  
          <p>Date de naissance <b style="color: #ff6141">*</b></p>
          <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="birthdate"
            v-validate="'required'"
            name="birthdate"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('birthdate')">{{ errors.first("birthdate") }}</span>
      </div>

        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
    
  
          <p> Nationalité <b style="color: #ff6141" >*</b> </p>
            <v-select
              v-validate="'required'"
              name="nationality"
              :reduce="rep => rep.id"
              class="w-full" label="name"
              v-model="nationality"
              :options="nationalities">
            </v-select>
          <span class="text-danger text-sm" v-show="errors.has('nationality')">{{ errors.first("nationality") }}</span>
          
          <p v-if="role !== 'managerSubcontractor'" >Compagnie<b style="color: #ff6141">*</b></p>
          <v-select
          v-if="role !== 'managerSubcontractor'"
              v-validate="'required'"
              name="company"
              :reduce="rep => rep.id"
              class="w-full" label="name"
              v-model="company"
              :options="companies">
            </v-select>
           <span v-if="role !== 'managerSubcontractor'" class="text-danger text-sm" v-show="errors.has('company')">{{ errors.first("company") }}</span>
          
           <p>Ancienneté dans l'entreprise <b style="color: #ff6141">*</b></p>
          <vs-input-number
          :min="0"
          :max="100"  
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="seniorityInTheCompany"
          v-model="seniorityInTheCompany"
          class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('seniorityInTheCompany')">{{ errors.first("seniorityInTheCompany") }}</span>
          
          <p>Fonction<b style="color: #ff6141">*</b></p>
          <v-select
              v-validate="'required'"
              name="job_function"
              :reduce="rep => rep.id"
              class="w-full" label="name"
              v-model="job_function"
              :options="functions">
            </v-select>
           <span class="text-danger text-sm" v-show="errors.has('job_function')">{{ errors.first("job_function") }}</span>
           <p>Ancienneté dans la fonction<b style="color: #ff6141">*</b></p>
          <vs-input-number
          :min="0"
          :max="100"  
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="seniorityInTheFunction"
          v-model="seniorityInTheFunction"
          class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('seniorityInTheFunction')">{{ errors.first("seniorityInTheFunction") }}</span>
          
          <p v-if="externalStaffOwnedByAnotherCompany ==1" >Nom de l'entreprise externe<b style="color: #ff6141">*</b></p>
          <vs-input
          v-if="externalStaffOwnedByAnotherCompany ==1" 
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="externalCompanyName"
            v-model="externalCompanyName"
            class="w-full"
          />
          <span v-if="externalStaffOwnedByAnotherCompany ==1" class="text-danger text-sm" v-show="errors.has('externalCompanyName')">{{ errors.first("externalCompanyName") }}</span>
          
        </div>

      <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
          
          <p v-if="externalStaffOwnedByAnotherCompany ==1" >Référence du contrat d'entreprise externe<b style="color: #ff6141">*</b></p>
          <vs-input
          v-if="externalStaffOwnedByAnotherCompany ==1" 
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="externalCompanyContractReference"
            v-model="externalCompanyContractReference"
            class="w-full"
          />
          <span v-if="externalStaffOwnedByAnotherCompany ==1" class="text-danger text-sm" v-show="errors.has('externalCompanyContractReference')">{{ errors.first("externalCompanyContractReference") }}</span>
          

          <p v-if="formations_tabCheck === true" >Identifiant du permis de conduire<b style="color: #ff6141">*</b></p>
          <vs-input
          v-if="formations_tabCheck === true"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="drivingLicenseID"
            v-model="drivingLicenseID"
            class="w-full"
          />
          <span v-if="formations_tabCheck === true" class="text-danger text-sm" v-show="errors.has('drivingLicenseID')">{{ errors.first("drivingLicenseID") }}</span>
          
          <p v-if="formations_tabCheck === true" >Ancienneté en conduite <b style="color: #ff6141">*</b></p>
          <vs-input-number
          v-if="formations_tabCheck === true"
          :min="0"
          :max="100"  
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="seniorityInDriving"
          v-model="seniorityInDriving"
          class="w-full"
          />
          <span v-if="formations_tabCheck === true" class="text-danger text-sm" v-show="errors.has('seniorityInDriving')">{{ errors.first("seniorityInDriving") }}</span>
          
          <p  >contrôle médical<b style="color: #ff6141">*</b></p>
          <vs-checkbox 
              v-model="medicalCheck"
              data-vv-validate-on="blur"
              v-validate="'required'"
              autocomplete
              name="medicalCheck"
              class="w-full">
          </vs-checkbox>
          <span  class="text-danger text-sm" v-show="errors.has('medicalCheck')">{{ errors.first("medicalCheck") }}</span>
          
          <p v-if="medicalCheck ==1">Date du contrôle médical <b style="color: #ff6141">*</b></p>
          <flat-pickr 
          v-if="medicalCheck ==1"
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="medicalCheckDate"
            v-validate="'required'"
            name="medicalCheckDate"
            class="w-full" />
          <span v-if="medicalCheck ==1" class="text-danger text-sm" v-show="errors.has('medicalCheckDate')">{{ errors.first("medicalCheckDate") }}</span>
  
            <!-- image -->
      <p  v-if="medicalCheck ==1" class="pt-4">Preuve médicale<b style="color: #ff6141" ></b> </p>
      <div  v-if="medicalCheck ==1" class="upload-img mt-1">
        <input  type="file" class="hidden" ref="uploadImgInput6" @change="updateCurrImg6"  accept="image/*">
        <span v-if="show_Case == 1" @click="showImage(name_file6)"><b style="color:orange">{{ name_file6 }}</b></span>
        <span v-if="show_Case == 0"><b style="color:orange">{{ name_file6 }}</b></span>

        <vs-button  @click="$refs.uploadImgInput6.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
      </div>
      </div>

    <vx-card class="mt-10" no-shadow card-border>
     <div class="vx-row ">
      <div class="vx-col w-full">
        <div class="flex items-end px-3">
          <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
          <span class="font-medium text-lg leading-none">Informations sur la formation </span>
        </div>
        <vs-divider />
      </div>
    </div>
    <div class="vx-row ">
  </div>
  <div class="vx-row ">
      <div class="vx-col w-full">
        <div class="vx-row">
      <div class="vx-col md:w-1/3 sm:w-1/3 w-full">
          <label class="text-sm opacity-75 mp-2">Formation<b style="color: #ff6141" >*</b></label>
          <v-select
              autocomplete
              name="formations"
              :reduce="rep => rep"
              class="w-full" label="name"
              v-model="formations_tab"
              :options="formations_list">
            </v-select>
         <!--  <vs-select
              @input= "formations_tab_change(tr)"
              :closeOnSelect="true"
              data-vv-validate-on="blur"
              autocomplete
              v-model="formations_tab"
              class="w-full "
              name="formations"
          >
          <vs-select-item :key="item4" :value="item4" :text="item4.name" v-for="item4 in formations_list" />
          </vs-select> -->
      </div>
    <div v-if="formations_tab" class="vx-col md:w-1/3 sm:w-1/3 w-full">
      <p >Preuve<b style="color: #ff6141" ></b> </p>
      <div class="upload-img mt-2">
        <input  type="file" class="hidden" ref="uploadImgInput7" @change="updateCurrImg7"  accept="image/*">
        <span><b style="color:orange">{{ name_file7 }}</b></span>
        <vs-button  @click="$refs.uploadImgInput7.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
      </div>
        
    </div>
  </div>
    </div>
    <div v-if="formations_tab" class="vx-col md:w-1/6 sm:w-1/2 w-full">
      <label class="text-sm opacity-75">Date de la formation<b style="color: #ff6141" >*</b></label>
      <flat-pickr 
      :config="configdateTimePicker1" 
      @on-change="onFromChange" 
      label-placeholder=" Date"
      v-model="dateOfTrainings_tab"
      name="dateOfTrainings"
      class="w-full" />
    </div>

    <div v-if="formations_tab"  class="vx-col  md:w-1/6 sm:w-1/2 w-full">
      <label class="text-sm opacity-75">Date d'obtention<b style="color: #ff6141" >*</b></label>
      <flat-pickr 
      :config="configdateTimePicker2" 
      @on-change="onToChange"
      label-placeholder=" Date"
      name="dateOfObtainings"
      v-model="dateOfObtainings_tab"
      class="w-full" />
     
    </div>

    <div v-if="formations_tab"  class="vx-col  md:w-1/6 sm:w-1/2 w-full">
      <label class="text-sm opacity-75">Organismes formateurs<b style="color: #ff6141" >*</b></label>
      <vs-input
      data-vv-validate-on="blur"
      name="trainerOrganisms"
      v-model="trainerOrganisms_tab"
      class="w-full" />

    </div>

    <div v-if="formations_tab" class="vx-col  md:w-1/6 sm:w-1/2 w-full">
      <label class="text-sm opacity-75">Références du certificat<b style="color: #ff6141" >*</b></label>
      <vs-input
      data-vv-validate-on="blur"
      name="idCertificateReferences"
      v-model="idCertificateReferences_tab"
      class="w-full" />

    </div>

    <div v-if="formations_tab" class="vx-col md:w-1/6 sm:w-1/2 w-full ">
      <label class="text-sm opacity-75">Dates de validité<b style="color: #ff6141" >*</b></label>
      <flat-pickr 
      :config="configdateTimePicker3" 
      @on-change="onFromChange_" 
      label-placeholder=" Date"
      v-model="validityDates_tab"
      name="validityDates"
      class="w-full" />
    </div>

    <div v-if="formations_tab" class="vx-col md:w-1/6 sm:w-1/2 w-full ">
    <label class="text-sm opacity-75">Prochaine mise à niveau<b style="color: #ff6141" >*</b></label>
    <flat-pickr 
      :config="configdateTimePicker4" 
      @on-change="onToChange_"
      label-placeholder=" Date"
      v-model="dateOfTheNextUpgrades_tab"
      name="dateOfTheNextUpgrades"
      class="w-full" />
    </div>

    <div v-if="formations_tab" class="vx-col md:w-full sm:w-full w-full ">
        <div class="flex flex-wrap justify-between mt-5 ">
            <vs-button v-if="!update_produitChek" :disabled="!validate" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_produit">Ajouter</vs-button>
            
            <vs-button v-if="update_produitChek" :disabled="!validate" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="updateProduit">Modifier</vs-button>

            <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData">Annuler</vs-button>
          </div>
    </div>
    </div>
  <div class="vx-row ">
    <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_computed">
    <template slot="header">
      <h3>
        Formation(s)
      </h3>
    </template>
    <template slot="thead">
      <vs-th hidden  >N°</vs-th>
      <vs-th>Formation</vs-th>
      <vs-th>Date de formation</vs-th>
      <vs-th>Date d'obtention</vs-th>
      <vs-th>Organisme formateur</vs-th>
      <vs-th>Référence du certificat d'identification</vs-th>
      <vs-th>Date de validité</vs-th>
      <vs-th>Prochaine mise à niveau</vs-th>
      <vs-th>Preuve</vs-th>

      <vs-th>Actions</vs-th>
    </template>

    <template slot-scope="{data}">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
      
        <vs-td hidden :data="data[indextr].id">
          {{data[indextr].id}}
        </vs-td>
        <vs-td :data="data[indextr].formations">
          {{data[indextr].formations.name}}
        </vs-td>
        <vs-td :data="data[indextr].dateOfTrainings">
          {{data[indextr].dateOfTrainings}}
        </vs-td>
        <vs-td :data="data[indextr].dateOfObtainings">
          {{data[indextr].dateOfObtainings}}
        </vs-td>
        <vs-td :data="data[indextr].trainerOrganisms">
          {{data[indextr].trainerOrganisms}}
        </vs-td>
         <vs-td :data="data[indextr].idCertificateReferences">
          {{data[indextr].idCertificateReferences}}
        </vs-td>
        <vs-td :data="data[indextr].validityDates">
          {{data[indextr].validityDates}}
        </vs-td>
        <vs-td :data="data[indextr].dateOfTheNextUpgrades">
          {{data[indextr].dateOfTheNextUpgrades}}
        </vs-td>
        <vs-td :data="data[indextr].file">
          {{data[indextr].file}}
        </vs-td>
        <vs-td class="whitespace-no-wrap">
          <div class="flex">
            <vs-button @click.stop="openfiles(data[indextr].file_url)" color="primary" size="small" icon-pack="feather" type="filled" icon="icon-eye"></vs-button>    

            <vs-button @click.stop="update_pro_chek(data[indextr])" color="warning" size="small" type="filled" icon="edit" class="ml-2"></vs-button>
            
            <vs-button @click.stop="deleteProduit(data[indextr].id , data[indextr].file)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
        
          </div>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
  </div>

    </vx-card>

    </div>
    <vs-button color="success"  class="float-right mt-4" @click="formation_validate()" >Envoyer</vs-button>
   </div>
    </vs-popup>

    <vs-popup
    v-if="activePrompt3" 
    fullscreen 
    class="calendar-event-dialog"
    title="Détails du Personnel"
    :active.sync="activePrompt3">
    <div class="clearfix">
      <div class="vx-row">
      <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
        <!-- NOM ET PRENOMS-->
        <p>Nom complet<b style="color: #ff6141">*</b></p>
        <vs-input
          disabled
          name="fullName"
          v-model="fullName"
          class="w-full"
        />

        <p> Type de contrat <b style="color: #ff6141" >*</b> </p>
          <vs-select
                disabled
                v-model="contractType"
                autocomplete
                name="contractType"
                class="w-full"
            >
          <vs-select-item :key="item" :value="item.key" :text="item.name" v-for="item in contract_choises" /></vs-select>
        
         
        <p>Type de carte<b style="color: #ff6141">*</b></p>
        <vs-select
            disabled
            v-model="cardType"
            autocomplete
            name="cardType"
            class="w-full"
        >
        <vs-select-item :key="item1" :value="item1.key" :text="item1.name" v-for="item1 in card_choises" /></vs-select>

          <p>N° de Carte ou Passport<b style="color: #ff6141">*</b></p>
          <vs-input
            disabled
            name="idCardOrPassportNumber"
            v-model="idCardOrPassportNumber"
            class="w-full"
          />

          <p>Personnel externe appartenant à une autre entreprise<b style="color: #ff6141">*</b></p>
          <vs-checkbox 
              disabled
              v-model="externalStaffOwnedByAnotherCompany"
              data-vv-validate-on="blur"
              v-validate="'required'"
              autocomplete
              name="externalStaffOwnedByAnotherCompany"
              class="w-full">
         </vs-checkbox>
          
          <p>Date de naissance <b style="color: #ff6141">*</b></p>
          <flat-pickr 
            disabled
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="birthdate"
            name="birthdate"
            class="w-full" />
      </div>

        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
    
  
          <p> Nationalité <b style="color: #ff6141" >*</b> </p>
            <v-select
              disabled
              name="nationality"
              :reduce="rep => rep.id"
              class="w-full" label="name"
              v-model="nationality"
              :options="nationalities">
            </v-select>

          <p v-if="role !== 'managerSubcontractor'">Compagnie<b style="color: #ff6141">*</b></p>
          <v-select
          v-if="role !== 'managerSubcontractor'"
              disabled
              name="company"
              :reduce="rep => rep.id"
              class="w-full" label="name"
              v-model="company"
              :options="companies">
            </v-select>
          
           <p>Ancienneté dans l'entreprise <b style="color: #ff6141">*</b></p>
          <vs-input-number
          disabled
          :min="0"
          :max="100"  
          name="seniorityInTheCompany"
          v-model="seniorityInTheCompany"
          class="w-full"
          />
          
          <p>Fonction<b style="color: #ff6141">*</b></p>
          <v-select
              disabled
              name="job_function"
              :reduce="rep => rep.id"
              class="w-full" label="name"
              v-model="job_function"
              :options="functions">
            </v-select>
          
          <p>Ancienneté dans la fonction<b style="color: #ff6141">*</b></p>
          <vs-input-number
          disabled
          :min="0"
          :max="100"  
          name="seniorityInTheFunction"
          v-model="seniorityInTheFunction"
          class="w-full"
          />
          
          <p v-if="externalStaffOwnedByAnotherCompany ==1" >Nom de l'entreprise externe<b style="color: #ff6141">*</b></p>
          <vs-input
          disabled
          v-if="externalStaffOwnedByAnotherCompany ==1" 
            name="externalCompanyName"
            v-model="externalCompanyName"
            class="w-full"
          />
          
        </div>

      <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
          
          <p v-if="externalStaffOwnedByAnotherCompany ==1" >Référence du contrat d'entreprise externe<b style="color: #ff6141">*</b></p>
          <vs-input
          disabled
          v-if="externalStaffOwnedByAnotherCompany ==1" 
            name="externalCompanyContractReference"
            v-model="externalCompanyContractReference"
            class="w-full"
          />
          

          <p v-if="formations_tabCheck === true">Identifiant du permis de conduire<b style="color: #ff6141">*</b></p>
          <vs-input
          v-if="formations_tabCheck === true"
            disabled
            name="drivingLicenseID"
            v-model="drivingLicenseID"
            class="w-full"
          />

          <p v-if="formations_tabCheck === true">Ancienneté en conduite <b style="color: #ff6141">*</b></p>
          <vs-input-number
          v-if="formations_tabCheck === true"
          disabled
          :min="0"
          :max="100"  
          name="seniorityInDriving"
          v-model="seniorityInDriving"
          class="w-full"
          />
          
          <p  >contrôle médical<b style="color: #ff6141">*</b></p>
          <vs-checkbox 
              disabled
              v-model="medicalCheck"
              autocomplete
              name="medicalCheck"
              class="w-full">
         </vs-checkbox>
           
          <p v-if="medicalCheck ==1">Date du contrôle médical <b style="color: #ff6141">*</b></p>
          <flat-pickr 
            disabled
            v-if="medicalCheck ==1"
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="medicalCheckDate"
            name="medicalCheckDate"
            class="w-full" />
                <!-- image -->
        <p  v-if="medicalCheck ==1" class="pt-4">Preuve médicale<b style="color: #ff6141" ></b> </p>
        <div  v-if="medicalCheck ==1" class="upload-img mt-1">
          <span v-if="show_Case == 1" @click="showImage(name_file6)"><b style="color:orange">{{ name_file6 }}</b></span>
          <span v-if="show_Case == 0"><b style="color:orange">{{ name_file6 }}</b></span>
          <vs-button  @click="showImage(name_file6)" color="primary" type="filled" icon-pack="feather" icon="icon-eye">Voir</vs-button>
      </div>
          
      </div>

    <vx-card class="mt-10" no-shadow card-border>
     <div class="vx-row ">
      <div class="vx-col w-full">
        <div class="flex items-end px-3">
          <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
          <span class="font-medium text-lg leading-none">Informations sur la formation </span>
        </div>
        <vs-divider />
      </div>
    </div>
    <div class="vx-row ">
  </div>
  
  <div class="vx-row ">
    <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_computed">
    <template slot="header">
      <h3>
        Formation(s)
      </h3>
    </template>
    <template slot="thead">
      <vs-th hidden  >N°</vs-th>
      <vs-th>Formation</vs-th>
      <vs-th>Date de formation</vs-th>
      <vs-th>Date d'obtention</vs-th>
      <vs-th>Organisme formateur</vs-th>
      <vs-th>Référence du certificat d'identification</vs-th>
      <vs-th>Date de validité</vs-th>
      <vs-th>Prochaine mise à niveau</vs-th>
      <vs-th>Preuve</vs-th>
      <vs-th>Action</vs-th>

    </template>

    <template slot-scope="{data}">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
      
        <vs-td hidden :data="data[indextr].id">
          {{data[indextr].id}}
        </vs-td>
        <vs-td :data="data[indextr].formations">
          {{data[indextr].formations.name}}
        </vs-td>
        <vs-td :data="data[indextr].dateOfTrainings">
          {{data[indextr].dateOfTrainings}}
        </vs-td>
        <vs-td :data="data[indextr].dateOfObtainings">
          {{data[indextr].dateOfObtainings}}
        </vs-td>
        <vs-td :data="data[indextr].trainerOrganisms">
          {{data[indextr].trainerOrganisms}}
        </vs-td>
         <vs-td :data="data[indextr].idCertificateReferences">
          {{data[indextr].idCertificateReferences}}
        </vs-td>
        <vs-td :data="data[indextr].validityDates">
          {{data[indextr].validityDates}}
        </vs-td>
        <vs-td :data="data[indextr].dateOfTheNextUpgrades">
          {{data[indextr].dateOfTheNextUpgrades}}
        </vs-td>
        <vs-td :data="data[indextr].file">
          {{data[indextr].file}}
        </vs-td>
        <vs-td class="whitespace-no-wrap">
          <div class="flex">
            <vs-button @click.stop="openfiles(data[indextr].file_url)" color="primary" size="small" icon-pack="feather" type="filled" icon="icon-eye"></vs-button>   
          </div>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
  </div>

    </vx-card>

    </div>
    <vs-button color="primary"  class="float-right mt-4" @click="formation_quit()" >Quitter</vs-button>
   </div>
    </vs-popup>

    <vs-popup classContent="popup-example" title="Ajouter votre commentaire" :active.sync="activePrompt4">
    <vs-textarea
      v-model="comment"
      rows="5"
      class="w-full" >
    </vs-textarea>
    <div class="flex flex-wrap justify-between mt-5 ">
    <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData_comfirmation">Annuler</vs-button>
    <vs-button  :disabled="!validate_comment" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="rejete_comfirmation">Valider</vs-button>
    </div>
    </vs-popup>

    <vs-popup classContent="popup-example" title="Détails du rejet" :active.sync="activePrompt5">
      
      <li class="mb-2" v-for="comment in details" :key="comment" >{{ comment.comment }}</li>

    </vs-popup>
  </div>
</template>
<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import VueImageLightboxCarousel from './SliderComponent'  
import { Validator } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
const French = require('flatpickr/dist/l10n/fr.js').default.fr

const dict = {
  custom: {
    fullName: {
      required: 'Le champ nom complet  est obligatoire'
    },
    externalCompanyContractReference: {
      required: 'Le champ référence du contrat d\'entreprise externe  est obligatoire'
    },
    medicalCheckDate: {
      required: 'Le champ date du contrôle médical est obligatoire'
    },
    contractType: {
      required: 'Le champ type de contrat est obligatoire'
    }, 
    cardType: {
      required: 'Le champ type de carte est obligatoire'
    },
    idCardOrPassportNumber: {
      required: 'Le champ numéro de carte d\'identité ou de passeport est obligatoire'
    },
    externalStaffOwnedByAnotherCompany: {
      required: 'Le champ personnel externe appartenant à une autre entreprise est obligatoire'
    },
    nationality: {
      required: 'Le champ nationalié est obligatoire'
    },
    company: {
      required: 'Le champ compagnie est obligatoire'
    },
    birthdate: {
      required: 'Le champ date de naissance est obligatoire'
    },
    seniorityInTheCompany: {
      required: 'Le champ ancienneté dans l\'entreprise est obligatoire'
    },
    insuranceCompany: {
      required: 'Le champ compagnie d\'assurance est obligatoire'
    },
    job_function: {
      required: 'Le champ fonction est obligatoire'
    },
    seniorityInTheFunction: {
      required: 'Le champ ancienneté dans la fonction est obligatoire'
    },
    externalCompanyName: {
      required: 'Le champ nom de l\'entreprise externe est obligatoire'
    },
    drivingLicenseID: {
      required: 'Le champ identifiant du permis de conduire est obligatoire'
    },
    seniorityInDriving: {
      required: 'Le champ ancienneté en conduite est obligatoire'
    },
    medicalCheck: {
      required: 'Le champ contrôle médical est obligatoire'
    },
    formations: {
      required: 'Le champ formtion est obligatoire'
    },
    dateOfTrainings: {
      required: 'Le champ date de formation est obligatoire'
    },
    dateOfObtainings: {
      required: 'Le champ date d\'obtention est obligatoire'
    },
    trainerOrganisms: {
      required: 'Le champ organisme formateur est obligatoire'
    },
    idCertificateReferences: {
      required: 'Le champ référence du certificat d\'identification est obligatoire'
    },
    validityDates: {
      required: 'Le champ date de validité est obligatoire'
    },
    dateOfTheNextUpgrades: {
      required: 'Le champ date des prochaines mises à niveau est obligatoire'
    }
  }
}

// register custom messages
Validator.localize('en', dict)
export default {
  components: {
    StatisticsCardLine,
    flatPickr,
    VueImageLightboxCarousel,
    swiper,
    swiperSlide
  },
  data () {
    return {
      show_Case: 0,
      formation_filter:'',
      nbr_staffs:'',
      configdateTimePicker1: {
        enableTime: 'fasle',
        minDate: null,
        maxDate: new Date(),
        dateFormat: 'Y-m-d',
        locale: French
      },
      configdateTimePicker2: {
        enableTime: 'fasle',
        maxDate: new Date(),
        minDate: null,
        dateFormat: 'Y-m-d',
        locale: French
      },
      configdateTimePicker3: {
        enableTime: 'fasle',
        minDate: null,
        maxDate: null,
        dateFormat: 'Y-m-d',
        locale: French
      },
      configdateTimePicker4: {
        enableTime: 'fasle',
        maxDate: null,
        minDate: null,
        dateFormat: 'Y-m-d',
        locale: French
      },
      medicalCertificateFile:'',
      role:'',
      fullName:'',
      contractType:'',
      cardType:'',
      idCardOrPassportNumber:'',
      externalStaffOwnedByAnotherCompany:0,
      birthdate:'',
      nationality:null,
      company:null,
      seniorityInTheCompany:0,
      job_function:null,
      seniorityInTheFunction:0,
      externalCompanyContractReference:'',
      medicalCheckDate:'',
      externalCompanyName:'',
      drivingLicenseID:'',
      seniorityInDriving:0,
      medicalCheck:0,
      formations:[],
      dateOfTrainings:[],
      dateOfObtainings:[],
      trainerOrganisms:[],
      idCertificateReferences:[],
      validityDates:[],
      dateOfTheNextUpgrades:[],

      formations_tabCheck:false,

      formations_tab:'',
      dateOfTrainings_tab:'',
      dateOfObtainings_tab:'',
      trainerOrganisms_tab:'',
      idCertificateReferences_tab:'',
      validityDates_tab:'',
      dateOfTheNextUpgrades_tab:'',

      activePrompt1:false,
      activePrompt2:false,
      activePrompt3:false,
      activePrompt5:false,
      activePrompt4:false,
      comment : '',
      staff_id : '',
      validationObject: {
        0: 'Rejetée',
        1: 'Encours',
        2: 'Validée'

      },
      details :[],
      configdateTimePicker: {
        enableTime: 'fasle',
        dateFormat: 'Y-m-d',
        locale: French
      },
      contratObject: {
        internUnderLongTermContract: 'Interne sous contrat longue durée',
        internalUnderShortTermContract: 'Interne  sous contrat courte durée',
        externalUnderShortTtermContract: 'Externe sous contrat courte durée ',
        externalUnderLongTermContract: 'Externe sous contrat lobgue durée'
     
      },
      contract_choises: [
        {
          key: 'internUnderLongTermContract',
          name: 'Interne sous contrat longue durée'
        },
        {
          key: 'internalUnderShortTermContract',
          name: 'Interne  sous contrat courte durée'
        },
        {
          key: 'externalUnderShortTtermContract',
          name: 'Externe sous contrat courte durée '
        },
        {
          key: 'externalUnderLongTermContract',
          name: 'Externe sous contrat longue durée'
        }
      ],
      card_choises: [
        {
          key: 'cni',
          name: 'Carte nationale d\'identité'
        },
        {
          key: 'passport',
          name: 'Passeport'
        }
      ],
      personExtern_choises: [
        {
          key: '1',
          name: 'Oui'
        },
        {
          key: '0',
          name: 'Non'
        }
      ],
      medical_choises: [
        {
          key: '1',
          name: 'Oui'
        },
        {
          key: '0',
          name: 'Non'
        }
      ],
      staffs: [],
      formations_list: [],
      functions: [],
      companies: [],
      selected: [],
      itemsPerPage: 20,
      isMounted: false,

      formations_response:'',
      id:'',
      old_produitID:[],
      repProduit:[],
      itemIndexProduit:-1,
      update_produitChek : false,
      Has_Med_Dos_Qty_update : false,
      id_produit:1,
      produits_computed:[],
      produit_submit:[],
      dataImg6:'',
      name_file6:'',
      dataImg7:'',
      name_file7:'',
      showLightbox: false,
      proofs:[],
      Vehicule_Image:false,
      images: [],
      datafiles:[],
      indexfiles:[],
      dataImg: [],
      AddNewdataImg_file: [],
      update_Image:true,
      dataImg1: [],
      dataImg1_delete: [],
      dataImg1_file: [],
      swiperOption: {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      dataImg7_store: [],
      name_file7_old :'',
      company_id :'',
      formation_object:[],
      medicalCertificateFile_show:''
    }
  },
  watch: {
  /* dateOfObtainings_tab (value) {
    if (value) {
      this.validityDates_tab = new Date(value)
    }
  }, */

  /* formations_tab (value) {
    if (value) {
      console.log('voir', value)
    }
  }, */
    job_function (value) {
      if (value) {
        const id = value
        this.$vs.loading()
        this.$http.get(`functions/${id}/`)
          .then((response) => {
          //this.formations_tabCheck = (response.data.name).toUpperCase()
            this.rech((response.data.name).substring(0, 8)) 
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      } else {
        this.formations_tabCheck = false
      }
 
    },
    formation_filter (value) {
      if (value) {
        const id = value
        this.$vs.loading()
        this.$http.post('staff-by-fonction/', { id})
          .then((response) => {
            this.staffs = response.data.staffs
            this.nbr_staffs = response.data.nb_total
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      } else {
        this.getAllstaffs()
      }
    },
    externalStaffOwnedByAnotherCompany (value) {
      if (!value) {
        this.externalCompanyName = ''  
        this.externalCompanyContractReference = ''  
      } 
    },
    medicalCheck (value) {
      if (!value) {
        this.medicalCheckDate = ''  
      } 
    }
  }, 
  computed: {
    validate_comment () {
      if (
        (
          this.comment !== ''  
        )) return true
    },
    validate () {
      if (
        (
          this.formations_tab !== ''
            && this.dateOfTrainings_tab !== ''
            && this.name_file7 !== ''
            && this.dateOfObtainings_tab !== ''
            && this.trainerOrganisms_tab !== ''
            && this.idCertificateReferences_tab !== ''
            && this.validityDates_tab !== ''
            && this.dateOfTheNextUpgrades_tab !== ''

   
        )) return true
    },

    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.staffs.length
    }
  },
  methods: {
    openfiles (file) {
      window.open(file, '_blank')
    },
    showImage (file) {
      window.open(`https://jra.mitigarisk.com/digira/uploads/${file}`, '_blank')
    },
    updateCurrImg6 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg6 = input.target.files
        this.name_file6 = input.target.files[0].name
        this.show_Case = 0
      }
    },
    updateCurrImg7 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg7 = input.target.files[0]
        this.name_file7 = input.target.files[0].name
      }
    },
    onToChange (selectedDates, dateStr) {
      this.$set(this.configdateTimePicker1, 'maxDate', dateStr)
    },
    onFromChange (selectedDates, dateStr) {
      this.$set(this.configdateTimePicker2, 'minDate', dateStr)
    },
    onToChange_ (selectedDates, dateStr) {
      this.$set(this.configdateTimePicker3, 'maxDate', dateStr)
    },
    onFromChange_ (selectedDates, dateStr) {
      this.$set(this.configdateTimePicker4, 'minDate', dateStr)
    },
    rapport (tr) {
      this.details = []
      this.activePrompt5 = true
      this.details = tr
    },
    rejete_comfirmation () {
      const input = {
        staff : this.staff_id,
        comment : this.comment
      }
      //const vehicle = this.staff_id
      //const comment = this.comment
      this.$vs.loading()
      this.$http.post('reject-staff/', input)
        .then(response => {
          this.formation_filter = ''
          this.getAllstaffs()
          window.getPrendTaCom.success('Rejet validé avec succès.', response)
        })
        .catch(() => {
          window.getPrendTaCom.error({ message: 'Rejet a échoué.' })
        })
      this.activePrompt4 = false
    },
    comfirmation (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'success',
        title: 'Confirmation',
        text: 'Confirmer la validation?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          const staff = id
          this.$vs.loading()
          this.$http.post('validate-staff/', {staff})
            .then(response => {
              this.formation_filter = ''
              this.getAllstaffs()
              window.getPrendTaCom.success('Personel validé avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'Validation échouée.' })
            })
        }
      })
    },

    resetData_comfirmation () {
      this.comment = ''
      this.staff_id = ''
      this.activePrompt4 = false
    },
    rejet_Pop (id) {
      this.staff_id = id
      this.comment = ''
      this.activePrompt4 = true
    },
    getOrderStatusColor (status) {
      if (status === 0) {
        return 'danger'
      } else if (status === 1) {
        return 'warning'
      } else if (status === 2) {
        return 'success'
      }
    },
    rech (name) {
      const list = [
        { name: 'Conducteur'},
        { name: 'Chauffeur' }
      ]
      const namerech = name.toUpperCase()
      let rep = []
      for (let i = 0; i < list.length; i++) {
        if (list[i].name.toUpperCase().includes(namerech)) {
          rep.push(list[i])
        }
      }
      rep = rep.length > 0 ? rep.sort((a, b) => a.name.localeCompare(b.name)) : rep

      if (rep.length) {
        this.formations_tabCheck = true
      } else {
        this.formations_tabCheck = false
      }

    //rep = rep.length > 0 ? rep.sort((a, b) => a.name.localeCompare(b.name)) : 'rien trouvé'

    },
    check_fonction (id) {
    
      this.$http.get(`functions/${id}/`)
        .then((response) => {
          this.rech((response.data.name).substring(0, 8))
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    formation_quit () {
      this.quit()
    },
    Show_data (ord) {
      this.intialise()
      this.check_fonction(ord.function.id)
      this.Has_Med_Dos_Qty_update = true
      this.id_produit =  ''
      this.update_HasId = ord.id
      this.produits_computed = []
      this.repProduit.length = 0

      this.fullName = ord.fullName
      this.contractType = ord.contractType
      this.cardType = ord.cardType
      this.idCardOrPassportNumber = ord.idCardOrPassportNumber
      this.externalStaffOwnedByAnotherCompany = ord.externalStaffOwnedByAnotherCompany
      this.birthdate = ord.birthdate
      this.nationality = ord.nationality
      this.company = ord.company
      this.seniorityInTheCompany = ord.seniorityInTheCompany
      this.job_function = ord.function.id
      this.externalCompanyContractReference = ord.externalCompanyContractReference
      this.medicalCheckDate = ord.medicalCheckDate
      this.seniorityInTheFunction = ord.seniorityInTheFunction
      this.externalCompanyName = ord.externalCompanyName
      this.drivingLicenseID = ord.drivingLicenseID
      this.seniorityInDriving = ord.seniorityInDriving
      this.medicalCheck = ord.medicalCheck
      this.name_file6 = ord.medicalCertificateFile.substring(8)

      for (let i = 0; i < ord.staffHasFormations.length; i++) {
        const obj = {
          id: '',
          formations: '',
          dateOfTrainings: '',
          dateOfObtainings: '',
          trainerOrganisms: '',
          idCertificateReferences: '',
          validityDates: '',
          dateOfTheNextUpgrades: '',
          file:'',
          file_url:''
        }
        obj.id = this.id_produit++
        obj.formations = ord.staffHasFormations[i].formation
        obj.dateOfTrainings = ord.staffHasFormations[i].dateOfTraining
        obj.dateOfObtainings = ord.staffHasFormations[i].dateOfObtaining
        obj.trainerOrganisms = ord.staffHasFormations[i].trainerOrganism
        obj.idCertificateReferences = ord.staffHasFormations[i].idCertificateReference
        obj.validityDates = ord.staffHasFormations[i].validityDate
        obj.dateOfTheNextUpgrades = ord.staffHasFormations[i].dateOfTheNextUpgrade
        obj.file = ord.staffHasFormations[i].staffHasFormationProofs[0].url.substring(8)
        obj.file_url = `https://jra.mitigarisk.com/digira/${ord.staffHasFormations[i].staffHasFormationProofs[0].url}`

        this.old_produitID.push(ord.staffHasFormations[i].id)
        this.repProduit.push(obj)
      }
      for (let j = 0; j <  this.repProduit.length; j++) {
        this.ADD_ITEM_PRODUIT(this.produits_computed, this.repProduit[j])
      }
   
      this.activePrompt3 = true

    },

    async Delete_Image (data) {
      if (this.id) {
        this.dataImg1.splice(data.key, 1)
        this.dataImg1_delete.push(data.id)
      } else {
        this.dataImg1.splice(data.key, 1)
        this.dataImg1_file.splice(data.key, 1)
        this.AddNewdataImg_file.splice(data.key, 1)
      }
    },


    Show_image (tr) {
      this.images = []
      const image_url = 'https://jra.mitigarisk.com/digira/'
      if (tr.vehicleProofs) {
        const length = tr.vehicleProofs.length
        for (let i = 0; i < length; i++) {
          this.images.push(image_url + tr.vehicleProofs[i].url)
        }
      } else {
        this.images = []
      }
    },
    resetColFilters () {
      this.$refs.filterCard.removeRefreshAnimation()
    },
 
    openLightbox () {
      this.showLightbox = true
      this.$refs.lightbox.showImage(0)
    },
    submit_produit () {
      this.produit_submit.length = 0
      const obj = {
        id: this.id_produit++,
        formations: this.formations_tab,
        dateOfTrainings: this.dateOfTrainings_tab,
        dateOfObtainings: this.dateOfObtainings_tab,
        trainerOrganisms: this.trainerOrganisms_tab,
        idCertificateReferences: this.idCertificateReferences_tab,
        validityDates: this.validityDates_tab,
        dateOfTheNextUpgrades: this.dateOfTheNextUpgrades_tab,
        file :this.name_file7
      }
      this.dataImg7_store.push(this.dataImg7)
      this.produit_submit = obj
      this.FIND_ITEM_PRODUIT(this.produits_computed, this.produit_submit)
      if (this.itemIndexProduit === -1) {
        this.ADD_ITEM_PRODUIT(this.produits_computed, this.produit_submit)
        this.$vs.notify({
          color:'success',
          icon:'done',
          text:'Formation ajoutée à la liste',
          position:'top-right'
        })
        this.validateFormation_intialise()
      } else if (this.itemIndexProduit !== -1) {
        this.$vs.notify({
          color:'warning',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          time:15000,
          text:'La formation existe déjà dans la liste',
          position:'top-center'
        })
      }
   
    },
    resetData_prod () {
      this.name_prod = ''
      this.quantity_prod = 1
    
    },
    resetData () {
      this.id = ''
      this.file = ''
      this.name_file7 = ''
      this.formations_tab = ''
      this.dateOfTrainings_tab = ''
      this.dateOfObtainings_tab = ''
      this.trainerOrganisms_tab = ''
      this.idCertificateReferences_tab = ''
      this.validityDates_tab = ''
      this.dateOfTheNextUpgrades_tab = ''
      this.update_produitChek = false
    }, 
    deleteProduit (id, file) {
      this.REMOVE_ITEM_PRODUIT(this.produits_computed, id)
      this.REMOVE_ITEM_IMAGE(this.dataImg7_store, file)
      this.$vs.notify({
        color:'success',
        icon:'delete',
        text:'Formation supprimée de la liste',
        position:'top-right'
      })
  
    },
    formations_tab_change (tr) {
      tr.residualRisk = parseInt((tr.residualRiskSeverity) * parseInt(tr.residualRiskProbability))
    },
    update_pro_chek (prod) {
      this.id = prod.id
      this.name_file7 = prod.file
      this.name_file7_old = prod.file
      this.formations_tab = prod.formations
      this.dateOfTrainings_tab = prod.dateOfTrainings
      this.dateOfObtainings_tab = prod.dateOfObtainings
      this.trainerOrganisms_tab = prod.trainerOrganisms
      this.idCertificateReferences_tab = prod.idCertificateReferences
      this.validityDates_tab = prod.validityDates
      this.dateOfTheNextUpgrades_tab = prod.dateOfTheNextUpgrades
      this.update_produitChek = true
    },
    updateProduit () {
      this.load()
      const obj = {
        id: this.id,
        file: this.name_file7,
        formations: this.formations_tab,
        dateOfTrainings: this.dateOfTrainings_tab,
        dateOfObtainings: this.dateOfObtainings_tab,
        trainerOrganisms: this.trainerOrganisms_tab,
        idCertificateReferences: this.idCertificateReferences_tab,
        validityDates: this.validityDates_tab,
        dateOfTheNextUpgrades: this.dateOfTheNextUpgrades_tab
      }
      this.UPDATE_ITEM_PRODUIT(this.produits_computed, obj)
      this.UPDATE_ITEM_IMAGE(this.dataImg7_store, this.name_file7_old, this.dataImg7)
      this.acceptAlert()
      this.resetData()

    },
    load () {
      this.$vs.loading()
      setTimeout(() => {
        this.$vs.loading.close()
      }, 500)
    },
    acceptAlert () {
      this.$vs.notify({
        color:'success',
        icon:'edit',
        text:'Formation modifié',
        position:'top-right'
      })
    },
    removeItemAll (arr, value) {
      let i = 0
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1)
        } else {
          ++i
        }
      }
      return arr
    },
    ADD_ITEM_PRODUIT (produits, produit) {
      produits.unshift(produit)
    },
    REMOVE_ITEM_IMAGE (produits, produit) {
      for (let j = 0; j <  produits.length; j++) {
        let prod = ''
        let prodIndex = ''
        let p1 = ''
        let p2 = ''
        prod = produits[j]
        prodIndex = produits.indexOf(produits[j])
        p1 = prod.name
        p2 = produit
        if (p1 === p2) {
          produits.splice(prodIndex, 1)
        } 
      }
    },
    UPDATE_ITEM_IMAGE (produits, old_file, produit) {
      for (let j = 0; j <  produits.length; j++) {
        let prod = ''
        let prodIndex = ''
        let p1 = ''
        let p2 = ''
        prod = produits[j]
        prodIndex = produits.indexOf(produits[j])
        p1 = prod.name
        p2 = old_file
        if (p1 === p2) {
          produits[prodIndex] = produit
        } 
      }
 
    },
    REMOVE_ITEM_PRODUIT (produits, produitId) {
      const ItemIndex = produits.findIndex((p) => p.id === produitId)
      produits.splice(ItemIndex, 1)
    },
    UPDATE_ITEM_PRODUIT (produits, produit) {
      const itemIndex = produits.findIndex((p) => p.id === produit.id)
      Object.assign(produits[itemIndex], produit)
    },
    FIND_ITEM_PRODUIT (produits, produit) {
      this.itemIndexProduit = produits.findIndex((p) => p.formations.id === produit.formations.id)
    },

    Edit (ord) {
      this.intialise()
      this.Has_Med_Dos_Qty_update = true
      this.show_Case = 1
      this.id_produit =  ''
      this.update_HasId = ord.id
      this.produits_computed = []
      this.repProduit.length = 0

      this.fullName = ord.fullName
      this.contractType = ord.contractType
      this.cardType = ord.cardType
      this.idCardOrPassportNumber = ord.idCardOrPassportNumber
      this.externalStaffOwnedByAnotherCompany = ord.externalStaffOwnedByAnotherCompany
      this.birthdate = ord.birthdate
      this.nationality = ord.nationality.id
      this.company = ord.company.id
      this.seniorityInTheCompany = ord.seniorityInTheCompany
      this.job_function = ord.function.id
      this.externalCompanyContractReference = ord.externalCompanyContractReference
      this.medicalCheckDate = ord.medicalCheckDate
      this.seniorityInTheFunction = ord.seniorityInTheFunction
      this.externalCompanyName = ord.externalCompanyName
      this.drivingLicenseID = ord.drivingLicenseID
      this.seniorityInDriving = ord.seniorityInDriving
      this.medicalCheck = ord.medicalCheck
      this.name_file6 = ord.medicalCertificateFile.substring(8)
      for (let i = 0; i < ord.staffHasFormations.length; i++) {
        const obj = {
          id: '',
          formations: '',
          dateOfTrainings: '',
          dateOfObtainings: '',
          trainerOrganisms: '',
          idCertificateReferences: '',
          validityDates: '',
          dateOfTheNextUpgrades: '',
          file:'',
          file_url:''
        }
        obj.id = this.id_produit++
        obj.formations = ord.staffHasFormations[i].formation
        obj.dateOfTrainings = ord.staffHasFormations[i].dateOfTraining
        obj.dateOfObtainings = ord.staffHasFormations[i].dateOfObtaining
        obj.trainerOrganisms = ord.staffHasFormations[i].trainerOrganism
        obj.idCertificateReferences = ord.staffHasFormations[i].idCertificateReference
        obj.validityDates = ord.staffHasFormations[i].validityDate
        obj.dateOfTheNextUpgrades = ord.staffHasFormations[i].dateOfTheNextUpgrade
        obj.file = (ord.staffHasFormations[i].staffHasFormationProofs[0].url).substring(8)
        obj.file_url = `https://jra.mitigarisk.com/digira/${ord.staffHasFormations[i].staffHasFormationProofs[0].url}`


        this.old_produitID.push(ord.staffHasFormations[i].id)
        this.repProduit.push(obj)
      }
      for (let j = 0; j <  this.repProduit.length; j++) {
        this.ADD_ITEM_PRODUIT(this.produits_computed, this.repProduit[j])
      }
      const length = ord.staffProofs.length
      for (let i = 0; i < length; i++) {
        this.dataImg1.push(`https://jra.mitigarisk.com/digira/${ord.staffProofs[i].url}`)
      }
      this.update_Image = true
      this.activePrompt2 = true

    },

    saveEditTeste () {
      const produits_store = this.produits_computed
      for (let i = 0; i < produits_store.length; i++) {
        this.New_produitID.push(produits_store[i].id)
      }
      for (let i = 0; i < this.New_produitID.length; i++) {
        const supprOp = this.New_produitID[i]
        this.removeItemAll(this.old_produitID, supprOp)
      }

    },
    addNewData () {
      this.intialise()
      this.update_produitChek = false
      this.update_Image = false
      this.activePrompt1 = true
    },
    validateFormation_intialise () {
      this.name_file7 = ''
      this.formations_tab = ''
      this.dateOfTrainings_tab = ''
      this.dateOfObtainings_tab = ''
      this.trainerOrganisms_tab = ''
      this.idCertificateReferences_tab = ''
      this.validityDates_tab = ''
      this.dateOfTheNextUpgrades_tab = ''
    },
    intialise () {
      this.$validator.reset()
      this.show_Case = 0
      this.medicalCertificateFile_show = ''
      this.formation_object = []
      this.formation_filter = ''
      this.Has_Med_Dos_Qty_update = false
      this.dataImg6 = ''
      this.name_file6 = ''
      this.fullName = ''
      this.contractType = ''
      this.cardType = ''
      this.idCardOrPassportNumber = ''
      this.externalStaffOwnedByAnotherCompany = 0
      this.birthdate = ''
      this.nationality = null
      this.company = null
      this.seniorityInTheCompany = 0
      this.job_function = null
      this.seniorityInTheFunction = 0
      this.externalCompanyContractReference = ''
      this.medicalCheckDate = ''
      this.externalCompanyName = ''
      this.drivingLicenseID = ''
      this.seniorityInDriving = 0
      this.medicalCheck = 0
      this.formations = []
      this.dateOfTrainings = []
      this.dateOfObtainings = []
      this.trainerOrganisms = []
      this.idCertificateReferences = []
      this.validityDates = []
      this.dateOfTheNextUpgrades = []
      this.formations_tab = ''
      this.dateOfTrainings_tab = ''
      this.dateOfObtainings_tab = ''
      this.trainerOrganisms_tab = ''
      this.idCertificateReferences_tab = ''
      this.validityDates_tab = ''
      this.dateOfTheNextUpgrades_tab = ''
      this.produits_computed = []
      this.activePrompt1 = false
    },
    quit () {
      this.show_Case = 0
      this.medicalCertificateFile_show = ''
      this.formation_filter = ''
      this.formation_object = []
      this.activePrompt1 = false
      this.activePrompt2 = false
      this.activePrompt3 = false
      this.$validator.reset()
      this.Has_Med_Dos_Qty_update = false
      this.dataImg6 = ''
      this.name_file6 = ''
      this.fullName = ''
      this.contractType = ''
      this.cardType = ''
      this.idCardOrPassportNumber = ''
      this.externalStaffOwnedByAnotherCompany = ''
      this.birthdate = ''
      this.nationality = null
      this.company = null
      this.seniorityInTheCompany = 0
      this.job_function = null
      this.seniorityInTheFunction = 0
      this.externalCompanyContractReference = ''
      this.medicalCheckDate = ''
      this.externalCompanyName = ''
      this.drivingLicenseID = ''
      this.seniorityInDriving = 0
      this.medicalCheck = 0
      this.formations = []
      this.dateOfTrainings = []
      this.dateOfObtainings = []
      this.trainerOrganisms = []
      this.idCertificateReferences = []
      this.validityDates = []
      this.dateOfTheNextUpgrades = []
      this.formations_tab = ''
      this.dateOfTrainings_tab = ''
      this.dateOfObtainings_tab = ''
      this.trainerOrganisms_tab = ''
      this.idCertificateReferences_tab = ''
      this.validityDates_tab = ''
      this.dateOfTheNextUpgrades_tab = ''
      this.produits_computed = []

    },
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`staffs/${id}/`)
            .then(response => {
              this.getAllstaffs()
              window.getPrendTaCom.success(this.$t('Suppressionstaff'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('Suppression') })
            })
        }
      })

    },
    formation_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    },
    submitData () {
      this.formation_object = []
      let produits_store = []
      produits_store = this.produits_computed
      const formdata = new FormData()

      for (let i = 0; i < produits_store.length; i++) {
        const obj = {
          formation: '',
          dateOfTraining: '',
          dateOfObtaining: '',
          trainerOrganism: '',
          idCertificateReference: '',
          validityDate: '',
          dateOfTheNextUpgrade: ''
        }

        obj.formation = produits_store[i].formations.id
        obj.dateOfTraining = produits_store[i].dateOfTrainings
        obj.dateOfObtaining = produits_store[i].dateOfObtainings
        obj.trainerOrganism = produits_store[i].trainerOrganisms
        obj.idCertificateReference = produits_store[i].idCertificateReferences
        obj.validityDate = produits_store[i].validityDates
        obj.dateOfTheNextUpgrade = produits_store[i].dateOfTheNextUpgrades
        this.formation_object.push(obj)
      }
      this.formation_object = JSON.stringify(this.formation_object)
      if (this.formations_tabCheck === false) {
        this.seniorityInDriving = ''  
        this.drivingLicenseID = ''
      }
      if (this.role === 'managerSubcontractor') {
        formdata.append('company', this.company_id)
      } else {
        formdata.append('company', this.company)
      }
      if (this.Has_Med_Dos_Qty_update === false) {
        this.id = ''
        formdata.append('fullName', this.fullName)
        formdata.append('contractType', this.contractType)
        formdata.append('cardType', this.cardType)
        formdata.append('idCardOrPassportNumber', this.idCardOrPassportNumber)
        formdata.append('birthdate', this.birthdate)
        formdata.append('nationality', this.nationality)
        formdata.append('seniorityInTheCompany', this.seniorityInTheCompany)
        formdata.append('function', this.job_function)
        formdata.append('seniorityInTheFunction', this.seniorityInTheFunction)
        formdata.append('externalCompanyContractReference', this.externalCompanyContractReference)
        formdata.append('medicalCheckDate', this.medicalCheckDate)
        formdata.append('externalCompanyName', this.externalCompanyName)
        formdata.append('drivingLicenseID', this.drivingLicenseID)
        formdata.append('seniorityInDriving', this.seniorityInDriving)
        formdata.append('formation_object', this.formation_object)

        if (this.name_file6) {
          formdata.append('medicalCertificateFile', this.dataImg6[0], this.name_file6)
        }
        if (this.medicalCheck) {
          formdata.append('medicalCheck', 1)
        } else {
          formdata.append('medicalCheck', 0)
        }
        if (this.externalStaffOwnedByAnotherCompany) {
          formdata.append('externalStaffOwnedByAnotherCompany', 1)
        } else {
          formdata.append('externalStaffOwnedByAnotherCompany', 0)
        } 
        //dataImg7_store
        if (this.name_file6) {
          formdata.append('medicalCertificateFile', this.dataImg6[0], this.name_file6)
        }
        if (this.dataImg7_store.length) {
          const length = this.dataImg7_store.length
          for (let i = 0; i < length; i++) {
            formdata.append('staffHasFormationProofs', this.dataImg7_store[i],  this.dataImg7_store[i].name)
          }
        }
      } else if (this.Has_Med_Dos_Qty_update === true) {
        this.id = this.update_HasId
        formdata.append('fullName', this.fullName)
        formdata.append('contractType', this.contractType)
        formdata.append('cardType', this.cardType)
        formdata.append('idCardOrPassportNumber', this.idCardOrPassportNumber)
        formdata.append('birthdate', this.birthdate)
        formdata.append('nationality', this.nationality)
        formdata.append('seniorityInTheCompany', this.seniorityInTheCompany)
        formdata.append('function', this.job_function)
        formdata.append('seniorityInTheFunction', this.seniorityInTheFunction)
        formdata.append('externalCompanyContractReference', this.externalCompanyContractReference)
        formdata.append('medicalCheckDate', this.medicalCheckDate)
        formdata.append('externalCompanyName', this.externalCompanyName)
        formdata.append('drivingLicenseID', this.drivingLicenseID)
        formdata.append('seniorityInDriving', this.seniorityInDriving)
        formdata.append('formation_object', this.formation_object)
        if (this.medicalCheck) {
          formdata.append('medicalCheck', 1)
        } else {
          formdata.append('medicalCheck', 0)
        }
        if (this.externalStaffOwnedByAnotherCompany) {
          formdata.append('externalStaffOwnedByAnotherCompany', 1)
        } else {
          formdata.append('externalStaffOwnedByAnotherCompany', 0)
        }
        if (this.dataImg6.length) {
          formdata.append('medicalCertificateFile', this.dataImg6[0], this.name_file6)
        }
        //dataImg7_store
        if (this.dataImg7_store.length) {
          const length = this.dataImg7_store.length
          for (let i = 0; i < length; i++) {
            formdata.append('staffHasFormationProofs', this.dataImg7_store[i],  this.dataImg7_store[i].name)
          }
        }
      }
      let url = 'staffs/'
      let methods = 'post'
      const message = {
        error: this.$t('save_error'),
        success: this.$t('staff_save')
      }
   
      if (this.id) {
        url += `${this.id}/`
        methods = 'put'
        message.success = this.$t('staff_update')
      }
      if (produits_store.length !== 0) {
        this.$vs.loading()
        this.$http[methods](url, formdata)
          .then((response) => {
            window.staffs.getAllstaffs()
            window.getPrendTaCom.success(message.success, response)
            this.quit()
          })
          .catch((error) => { 

            const clefs = Object.keys(error.response.data)
            for (let i = 0; i < clefs.length; i++) {
              const item = clefs[i]
              if (item === 'fullName') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Nom complet',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'contractType') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Type de contrat',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'cardType') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Type de carte',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'idCardOrPassportNumber') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Numéro de carte d\'identité ou de passeport',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'externalStaffOwnedByAnotherCompany') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Personnel externe appartenant à une autre entreprise',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'birthdate') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Date d\'annivaissaire',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'nationality') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Nationalité',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'company') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Compagnie',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'seniorityInTheCompany') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Ancienneté dans l\'entreprise',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'medecineHasDosageHasQuantity') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'compagnie d\'assurance',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'function') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'fontion',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'seniorityInTheFunction') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Ancienneté dans la fonction',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'externalCompanyName') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'L\'entreprise externe',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'externalCompanyContractReference') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Identifiant de l\'entreprise externe',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'drivingLicenseID') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Identifiant du permis',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'seniorityInDriving') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Ancienneté en conduite',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'medicalCheck') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Contrôle médical',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'medicalCheckDate') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Date de contrôle médical',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'formations') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Formtaion',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'dateOfTrainings') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Date de formation',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'dateOfObtainings') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Date d\'obtention',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'trainerOrganisms') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Organisme formateur',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'idCertificateReferences') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Référence du certificat d\'identification',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'validityDates') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Date de validité est obligatoire',
                  color: 'warning',
                  position: 'top-left'
                })
              }
              if (item === 'dateOfTheNextUpgrades') {
                this.$vs.notify({
                  time: 6000,
                  title: 'ATTENTION',
                  text: 'Date des prochaines mises à niveau',
                  color: 'warning',
                  position: 'top-left'
                })
              
              }
              this.$vs.notify({
                time: 8000,
                title: 'ENREGISTREMENT',
                text: error.response.data.error,
                color: 'danger',
                position: 'top-right'
              })
              this.$vs.loading.close()
            }

            window.getPrendTaCom.error(message.error)
          })
      } else {
        this.$vs.notify({
          color:'warning',
          icon:'info',
          text:'liste de formation vide',
          time:15000,
          position:'top-center'
        }) 
        this.$vs.loading.close()
      }
    },
    getAllstaffs () {
      this.$vs.loading()
      this.$http.get('activated-staffs/')
        .then((response) => {
          this.staffs = response.data
          //this.nbr_staffs = response.data.nb_total
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllNationalities () {
      this.$vs.loading()
      this.$http.get('nationalities/')
        .then((response) => {
          this.nationalities = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllCompanies () {
      this.$vs.loading()
      this.$http.get('companies/')
        .then((response) => {
          this.companies = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllFunctions () {
      this.$vs.loading()
      this.$http.get('functions/')
        .then((response) => {
          this.functions = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllFormation () {
      this.$vs.loading()
      this.$http.get('formations/')
        .then((response) => {
          this.formations_list = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
 
  },
  created () {
    const user_role = JSON.parse(localStorage.getItem('userInfo')).role
    this.role = user_role
    if (user_role === 'managerSubcontractor') {
      this.company_id = JSON.parse(localStorage.getItem('userInfo')).company.id
    }
    window.staffs = this
    this.getAllstaffs()
    this.getAllNationalities()
    this.getAllFunctions()
    this.getAllCompanies()
    this.getAllFormation()

  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          padding: 20px;
          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check{
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>


<style scoped>

</style>

<style scoped>

</style>
